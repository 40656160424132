#carousel {
    /* Ensure that the contents flow horizontally */
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
  }
  
  #carousel.vertical {
    flex-direction: column;
  }
  
  /* 2018 spec - For Safari 11, Chrome 69+ */
  #carousel.snap {
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
  }
  
  #carousel.snap > div {
    scroll-snap-align: center;
  }
  
  #carousel.snap.vertical {
    flex-direction: column;
    scroll-snap-type: y mandatory;
  }
  
  
  
  /* 2015 spec - For Firefox, Edge, IE */
  #carousel.snap {
    scroll-snap-type: mandatory;
    -ms-scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(100%);
    -ms-scroll-snap-points-x: repeat(100%);
  }
  
  #carousel.snap.vertical {
    scroll-snap-points-x: initial;
    -ms-scroll-snap-points-x: initial;
    scroll-snap-points-y: repeat(100%);
    -ms-scroll-snap-points-y: repeat(100%);
  }
  
  
  /* Below here is styling, not important for the actual example, just to make it look nice. No need to look down here! */
  
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  #carousel {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  #carousel > div {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
  
  #carousel-1 {
    background-color: #e34747;
  }
  
  #carousel-2 {
    background-color: #5ab92c;
  }
  
  #carousel-3 {
    background-color: #226de2;
  }
  
  #controls {
    position: fixed;
    bottom: 10px;
    left: 10px;
  }
  
  #controls button {
    padding: 5px 10px;
  }

  
.carousel-scroll-bottom{
    max-height: 100vh;
    overflow-x: scroll;
    /* overflow-x: hidden; */
}
  
.carousel-scroll-bottom::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

.carousel-scroll-bottom::-webkit-scrollbar-thumb {
    border: 2px solid rgb(222, 222, 222);
    background-color: rgb(222, 222, 222);
    border-radius: 50px;    
}