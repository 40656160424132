:root {
  /* --bkgBlocks: #1b233842; 
  --white: rgb(160, 174, 192);
  --gris1: #edf2f6;
  --gris2: #2f3848;
  --gris3: #1a202d;
  --negro: #000000;
  --dark: rgb(11, 15, 25);
  --dark2: rgb(17, 24, 39);
  --headingFont:'Raleway', sans-serif;
  --textFont:  'Roboto', sans-serif;
  --bkg: rgb(17, 24, 39);
  --btn-hover: #59657a;


  --theme-dark: rgb(17 24 39);
  --theme-light: #f5f7f9;
  --dark-color-letter: rgb(255, 255, 255);
  --light-color-letter: rgb(17 24 39); */
  --bkgBlocks: white; 
  --white: rgb(160, 174, 192);
  --gris1: #edf2f6;
  --gris2: #2f3848;
  --gris3: white;
  --negro: #000000;
  --dark: rgb(11, 15, 25);
  /* --dark: white; */
  --dark2: white;
  --headingFont:'Raleway', sans-serif;
  --textFont:  'Roboto', sans-serif;
  --bkg: white;
  --btn-hover: #59657a;


  --theme-dark: white;
  --theme-light: #f5f7f9;
  --dark-color-letter: rgb(255, 255, 255);
  --light-color-letter: white;
}

/* 
*/

/* -----[ FONTS ]----- */
@font-face {
  font-family: "mulish-black";   /*Can be any text*/
  src: local("Mulish-Black"),
    url("./fonts/Mulish-Black.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-black-italic";   /*Can be any text*/
  src: local("Mulish-BlackItalic"),
    url("./fonts/Mulish-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-bold";   /*Can be any text*/
  src: local("Mulish-Bold"),
    url("./fonts/Mulish-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-bold-italic";   /*Can be any text*/
  src: local("Mulish-BoldItalic"),
    url("./fonts/Mulish-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-extra-bold";   /*Can be any text*/
  src: local("Mulish-ExtraBold"),
    url("./fonts/Mulish-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-extra-bold-italic";   /*Can be any text*/
  src: local("Mulish-ExtraBoldItalic"),
    url("./fonts/Mulish-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-extra-light";   /*Can be any text*/
  src: local("Mulish-ExtraLight"),
    url("./fonts/Mulish-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "extra-light-italic";   /*Can be any text*/
  src: local("Mulish-ExtraLightItalic"),
    url("./fonts/Mulish-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-italic";   /*Can be any text*/
  src: local("Mulish-Italic"),
    url("./fonts/Mulish-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-italicw";   /*Can be any text*/
  src: local("Mulish-ItalicW"),
    url("./fonts/Mulish-ItalicW.ttf") format("truetype");
}

@font-face {
  font-family: "mulish-regular";   /*Can be any text*/
  src: local("Mulish-Regular"),
    url("./fonts/Mulish-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "semibold";   /*Can be any text*/
  src: local("Mulish-SemiBold"),
    url("./fonts/Mulish-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "semibold-italic";   /*Can be any text*/
  src: local("Mulish-SemiBoldItalic"),
    url("./fonts/Mulish-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "wulish";   /*Can be any text*/
  src: local("Mulish-W"),
    url("./fonts/Mulish-W.ttf") format("truetype");
}

html, body{
  background-color: #f1f1f1!important;
}

.full-black-color{ 
  color: black !important;
  background: white !important;
  padding: 3px 0px 0px 10px !important;
}

span{
  font-family: "semibold";
}
p{
  margin: 10px 0px 10px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
  font-family: "semibold";
  /* font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  color: rgb(160, 174, 192);
}

.alert-container{padding-top: 20px;}
.alerta-error svg{color: rgb(244, 67, 54) !important;}
.alerta-ok{
  background-color: rgb(109, 211, 109);
  color: var(--blanco);
}

.bg-dark-body{background-color:var(--dark) !important;}
.bg-dark-container{background-color:var(--dark2) !important;}

.btn {
  margin-top: 2rem;
  font-family: var(--headingFont);
  padding: 1.5rem;
  font-size: 1.4;
  font-weight: 400;
  border-radius: .5rem;
  border: none;
  transition: background-color .3s ease;
}
.btn:hover {
  cursor: pointer;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{border-color:white !important;}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{border-color:white   !important;}

.field-info .MuiOutlinedInput-notchedOutline{
  border-color: rgb(45, 55, 72);
  color: white !important;
}
.field-info{
  margin: 20px 0px;
}

/* .field-info input, svg{color: rgb(255, 255, 255) !important;} */
.field-info label{color: rgb(160, 174, 192);}
.field-info .MuiOutlinedInput-notchedOutline.btn:hover{border-color: white;}
.field-logo{
  padding: 30px 0px;
  width: 100%;
  text-align: center;
}
.field-logo img{width: 50%;}

.link-text{padding: 5px 0px;}

.link-text a{
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-decoration: none !important;
  color: black !important;
}

.link-text a:hover{text-decoration: underline !important;}

.line-divider{
  border-color: rgb(160, 174, 192) !important;
  margin: 35px 0px 20px 0px;
}

.height-adjust-display{min-height: 38px !important;}

/* .dark-theme{ background-color: var(--theme-dark) !important;} */
/* .dark-theme-letter{ color: var(--dark-color-letter) !important; } */
/* .dark-theme p{ color: white !important} */
/* .dark-theme span, th, td{ color: white !important} */
/* .dark-theme { color: var(--dark-color-letter) !important} */


.light-theme{ background-color: var(--theme-light) !important; }
.light-theme{ background-color: black; }

.light-theme-letter{ color: var(--light-color-letter) !important; }
.light-theme p{color:#000000 !important}
.light-theme span{color:#000000 !important}
.light-theme svg{color:#000000 !important}

.text-center{text-align: center !important;}

.shadow{box-shadow: rgb(0 0 0 / 24%) 0px 10px 15px;}

.hrDrawer{    
  margin: 24px 0px !important;
  flex-shrink: 0 !important;
  border-style: solid !important;
  border-width: 0px 0px thin !important;
  border-color: rgb(45, 55, 72) !important;
}

.hrDrawerBottom{
  flex-shrink: 0 !important;
  border-style: solid !important;
  border-width: 0px 0px thin !important;
  border-color: rgb(45, 55, 72) !important;
}

.templateBackground{
  background-color: var(--dark);
}

.test{
  background:rgb(244, 67, 54) !important;
}

.iconSend svg{
  align-self: center;
  height: 100%;
}

.emisor-dark{
  background: #023b51a6;
  display: inline-flex;
  padding: 10px 15px;
  border-radius: 5px;
  width: 60%;
  text-align: 'end';
  margin-right: 10px;
}

.emisor-light{
  background: #98d7efa6;
  display: inline-flex;
  padding: 10px 15px;
  border-radius: 5px;
  width: 60%;
  text-align: 'end';
  margin-right: 10px;
}

.receiver-light{
  background: #c8c2c2bf;
  margin-left: 10px;
  display: inline-flex;
  padding: 10px 15px;
  border-radius: 5px;
  width: 60%;
}

@media screen and ( min-width: 850px ) and ( max-height: 530px )
{
    .body-chat{
      height: 50vh;
    }
}

.receiver-dark{
  background: #3c3b3bbf;
  margin-left: 10px;
  display: inline-flex;
  padding: 10px 15px;
  border-radius: 5px;
  width: 60%;
}

.scrollStyle{
  height: 100%;
  overflow-y: scroll;
  background-image: url('../src/img/backgroundConversations.png');
}

.table-cell-standard{
  background: #FAFAFA !important;
}

.scrollStyle::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;

}

.scrollChat{
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollChat::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;

}
.scrollChat::-webkit-scrollbar-thumb {
  border: 3px solid var(--gris2);
  border-radius: 50px;
}


.scroll-table-component{
  height: fit-content;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll-table-component::-webkit-scrollbar {
  width: 5px;
  height: 9px;

}
.scroll-table-component::-webkit-scrollbar-thumb {
  border: 2px solid rgb(222, 222, 222);
  background-color: rgb(222, 222, 222);
  border-radius: 50px;
  
}


.scroll-table-component-side-left{
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll-table-component-side-left::-webkit-scrollbar {
  width: 5px;
  height: 9px;

}
.scroll-table-component-side-left::-webkit-scrollbar-thumb {
  border: 2px solid rgb(222, 222, 222);
  background-color: rgb(222, 222, 222);
  border-radius: 50px;
  
}

.scrollHide{
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollHide::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;

}
.scrollHide::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  border-radius: 50px;
}



.scroll-inventory{
  height: 100%;
  overflow: scroll;
}

.scroll-inventory::-webkit-scrollbar {
  width: 1px;
  height: 5px;
  border: none;
  background-color: transparent;

}
.scroll-inventory::-webkit-scrollbar-thumb {
  border: 3px solid white;
  border-radius: 50px;
}

.scrollStyle::-webkit-scrollbar-thumb {
  border: 3px solid var(--gris2);
  border-radius: 50px;
}

.scrollTable::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  background-color: transparent;
}

.scrollTable::-webkit-scrollbar-thumb {
  border: 3px solid var(--gris2);
  border-radius: 50px;
  width: 20px;
}

.scrollTable{
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid var(--gris2);
  border-radius: 0px 0px 5px 5px;
}

.scroll-table-leads::-webkit-scrollbar {
  width: 1px;
  height: 5px;
  border: none;
  background-color: transparent;
}

.scroll-table-leads::-webkit-scrollbar-thumb {
  border: 3px solid var(--gris2);
  border-radius: 50px;
}

.scroll-table-leads{
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid var(--gris2);
  border-radius: 0px 0px 5px 5px;
}

.listTabs::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.listTabs::-webkit-scrollbar-thumb {
  border: 3px solid var(--gris2);
  border-radius: 50px;
}

.listTabs{
  width: 100%; 
  overflow-y: scroll;
  height: 44vh;
  border-bottom: 1px solid var(--gris2);
  border-radius: 0px 0px 5px 5px;
}

.headerChat{
  height: 10%;
  display: flex; 
  border-bottom: 1px solid var(--gris2); 
  padding: 5px 20px;
  background-color: var(--bkgBlocks);
}

.searchField:focus{outline: none !important;}
.inputMessage:focus{outline: none !important;}

.inputMessage{
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  padding: 15px;
  color:rgb(138, 128, 153)
}

.iconSearch span{
  top: 15px;
  right: -10px
}

.item-menu-options{
  color: black !important;
}

.input-login input{
  color: black !important;
}


.control-table-scroll td {
  border-left: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
}

.control-table-scroll tbody {
  display: block;
  height: 50vh;
  overflow: auto;
}
.control-table-scroll thead, .control-table-scroll tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;/* even columns width , fix width of table too*/
}


.control-table-scroll thead {
  text-align: center;
  /* width: 100%; */
}

.text-extra-bold span{ 
  font-family: 'mulish-extra-bold' !important;
}

.table-radius-especial:first-child th:first-child {
  border-top-left-radius: 8px;
}
.table-radius-especial:first-child th:last-child {
  border-top-right-radius: 8px;
}
