body{
    /* background-color: #0b0f19 !important; */
}

.bloque{
    height: 100vh;
    width:  100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #0b0f19 !important;
}

.bloque-sigin{
    background: white !important;
    width: auto;
    min-width: 300px;
    min-height: 70vh;
    height: fit-content;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.inputs-box input{
    padding: 5px 8px !important;
    font-size: 12px;
    color: black;
    width: 100% !important;
    border: 1px solid black !important;
    border-radius: 6px;
    background-color: transparent !important;
    outline: none;
    margin-bottom: 15px;
}

.contenedor-btn-sigin{
    position: absolute;
    bottom: 15px;
    width: calc(100% - 40px);
}

.contenedor-btn-sigin input{
    cursor: pointer;
    /* background: #2f3848; */
    border-radius: 8px;
    font-size: 12px;
    padding: 5px 0px 10px 0px;
    color: white !important;
    font-family: "semibold" !important;
    width: 100%;
}

.inputs-box p{
    font-size: 12px;
    color: black;
    font-family: "semibold" !important;
}